import(/* webpackMode: "eager" */ "/home/corleone/components/atoms/buttons/text/primary.tsx");
;
import(/* webpackMode: "eager" */ "/home/corleone/components/atoms/typography/paragraph.tsx");
;
import(/* webpackMode: "eager" */ "/home/corleone/components/atoms/typography/title.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/corleone/node_modules/@mui/icons-material/esm/ErrorTwoTone.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/corleone/node_modules/@mui/icons-material/esm/LinkTwoTone.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/corleone/node_modules/@mui/material/esm/Container/Container.js");
;
import(/* webpackMode: "eager" */ "/home/corleone/node_modules/@mui/material/esm/Grid/Grid.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/corleone/node_modules/@mui/material/esm/Paper/Paper.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/corleone/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/home/corleone/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/corleone/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");

'use client'

import { Button, Typography } from '@mui/material'

// Types
import type { ButtonProps } from '@/types/components/atoms/button'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

const PrimaryButtonAtom = (props: ButtonProps) => {
	// Props
	const { lng, text, type, size, color, startIcon, endIcon, disabled, disableRipple, onClick, onDoubleClick, onTouchStart, onTouchEnd, sx, children } = props

	// Varaibles
	const { t } = useTranslation(lng)

	return (
		<Button
			type={type}
			variant="contained"
			size={size}
			color={color}
			startIcon={startIcon}
			endIcon={endIcon}
			disabled={disabled}
			disableElevation
			disableRipple={disableRipple}
			disableTouchRipple={disableRipple}
			onClick={onClick}
			onDoubleClick={onDoubleClick}
			onTouchStart={onTouchStart}
			onTouchEnd={onTouchEnd}
			sx={{
				minWidth: 'auto',
				textTransform: 'inherit',
				...(!text && {
					px: 1,
					'& .MuiButton-startIcon': { mx: 'inherit' }
				}),
				...(!color && {
					color: 'primary.contrastText',
					bgcolor: 'primary.dark',
					'&:hover': {
						color: 'primary.contrastText',
						bgcolor: 'primary.dark'
					}
				}),
				...(disableRipple && { cursor: 'default' }),
				...sx
			}}
		>
			{text && (
				<Typography component="span" fontSize="1em" textOverflow="ellipsis" overflow="hidden" sx={{ textWrap: 'nowrap' }}>
					{t(text)}
				</Typography>
			)}

			{children}
		</Button>
	)
}

export default PrimaryButtonAtom
